::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #449386;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #449386;
}

* {
  scrollbar-width: 8px;
  scrollbar-color: #449386 #dcdcdc;
}

.simplebar-track {
  background-color: #dcdcdc;
  border-radius: 8px;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1 !important;
  background: #449386;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.simplebar-horizontal {
  right: 25px !important;
  height: 8px !important;
}

.simplebar-vertical {
  bottom: 25px !important;
  width: 8px !important;
}

.simplebar-mask {
  @apply !mb-[25px] !mr-[25px] rounded-lg border border-solid shadow-md;
}

.simplebar-content-wrapper {
  min-height: 200px !important;
}
