@tailwind base;
@tailwind components;
@tailwind utilities;

@import './scrollbar.css';

/* Modern CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a class to avoid conflicts */
.reset-list {
  list-style: none;
}

/* Core body defaults while preserving existing styles */
html,
body {
  padding: 0;
  margin: 0;
  background-color: white;
  font-family: WorkSans;
  height: 100vh;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Make media elements easier to work with */
.reset-media {
  display: block;
  max-width: 100%;
}

/* Preserve existing modal open state */
body.modalOpen {
  overflow: hidden;
}

/* Links styling */
a {
  color: inherit;
  text-decoration: none;
}

/* Box sizing rule */
* {
  box-sizing: border-box;
}

/* Checkbox styles */
input:checked + svg {
  display: block;
}

/* Font declarations */
@font-face {
  font-family: Gordita;
  src: url(/fonts/Gordita.otf);
}

@font-face {
  font-family: GorditaBold;
  src: url(/fonts/Gordita-Bold.otf);
}

@font-face {
  font-family: Poppins;
  src: url(/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: WorkSans;
  src: url(/fonts/WorkSans-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: WorkSans;
  src: url(/fonts/WorkSans-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: WorkSans;
  src: url(/fonts/WorkSans-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: WorkSans;
  src: url(/fonts/WorkSans-Black.ttf);
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.word-separator {
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  height: 0;
  width: 116px;
}

[type='checkbox']:checked {
  background-image: url('/checkbox-checked-black.svg') !important;
}
[type='checkbox']:focus {
  outline: 0;
  box-shadow: none;
}

.tooltip-arrow:before {
  content: '';
  transform: rotate(45deg);
  visibility: visible;
}
.tooltip-arrow,
.tooltip-arrow:before {
  background: inherit;
  height: 8px;
  position: absolute;
  width: 8px;
  bottom: -2px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='text']:focus {
  box-shadow: none;
}

.radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioContainer:has(input:checked) {
  color: #449386;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: white;
  border: 1px solid #c1c1c1;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .checkmark {
  background-color: #72c0b4;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
  border: 1px solid #449386;
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
  top: 7px;
  left: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #449386;
}

::-webkit-file-upload-button {
  background: #009fa0;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  display: block;
  margin-bottom: 4px;
}

.Toastify__toast-container--bottom-center {
  bottom: 9px;
  left: 268px;
  width: calc(100% - 316px);
  transform: unset;
  padding: 0px;
}

.Toastify__toast-container--bottom-left {
  bottom: 9px;
  width: calc(100% - 30px);
  transform: unset;
  padding: 0px;
}

@media only screen and (max-width: 640px) {
  .Toastify__toast-container--bottom-center {
    bottom: 0px;
    left: 0px;
    width: 100%;
    transform: unset;
    padding: 0px;
  }
}

.toast-blue {
  background-color: #003366;
  color: white;
}

.toast-blue .Toastify__close-button--default {
  color: white;
  opacity: 1;
}

.toast-blue .Toastify__close-button > svg {
  width: 20px;
  height: 20px;
}

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 10px 10px !important;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  border-radius: 8px !important;
  border: none !important;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

ReactFlagsSelect-module_selectValue__152eS {
  width: 90%;
}

.larger .ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 5px 10px !important;
}

.ReactFlagsSelect-module_selectValue__152eS {
  max-width: 180px;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type='password']:not(:placeholder-shown) {
  font-family: WorkSans;
  letter-spacing: 0.125em;
  font-size: 1.25em;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.StripeElement {
  border-radius: 8px !important;
  border: 1px solid #c1c1c1 !important;
  padding: 15px 10px !important;
  height: unset !important;
}

.StripeElement--focus {
  border: 1px solid #449386 !important;
}

.StripeElement--invalid {
  border: 1px solid #ed4f00 !important;
}

.ElementsApp {
  font-size: 16px !important;
}

.ElementsApp,
.ElementsApp .InputElement {
  font-size: 16px !important;
  color: black !important;
}

.is-focused .InputElement {
  color: #449386;
}

/* Reduced motion preferences */
@media (prefers-reduced-motion: reduce) {
  .motion-reduce {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Reset button styles when needed */
.reset-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
